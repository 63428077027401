import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations, mapActions } from "vuex";
import { queryBalance } from "@/lib/polkadotProvider/query/balance";
import localStorage from "@/lib/local-storage";
import getEnv from "@/utils/env";
import { queryGetAssetBalance, queryGetAllOctopusAssets } from "@/lib/polkadotProvider/query/octopus-assets";
export default {
  name: "HeaderUserInfo",
  computed: {
    ...mapState({
      walletBalance: state => state.substrate.walletBalance,
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet,
      web3: state => state.metamask.web3,
      lastEventData: state => state.substrate.lastEventData
    })
  },
  data: () => ({
    balance: 0,
    polkadotAddress: "",
    ethRegisterAddress: null,
    polkadotBalance: 0,
    polkadotWallets: [{
      name: "debio",
      icon: "debio-logo",
      currency: "DBIO",
      unit: "ether",
      balance: 0
    }, {
      name: "usdt",
      icon: "tether-logo",
      currency: "USDTE",
      unit: "mwei",
      balance: 0
    }],
    isConnected: false,
    octopusAsset: []
  }),
  created() {
    this.polkadotWallets.forEach(wallet => {
      if (wallet.name === "usdt") {
        wallet.tokenId = getEnv("VUE_APP_DEBIO_USDT_TOKEN_ID");
      }
    });
  },
  methods: {
    ...mapMutations({
      setWalletBalance: "substrate/SET_WALLET_BALANCE",
      setUSDTBalance: "substrate/SET_USDT_BALANCE",
      setPolkadotWallet: "substrate/SET_POLKADOT_WALLET",
      clearWallet: "metamask/CLEAR_WALLET"
    }),
    ...mapActions({
      clearAuth: "auth/clearAuth"
    }),
    async fetchWalletBalance() {
      try {
        const balanceNumber = await queryBalance(this.api, this.wallet.address);
        this.setWalletBalance(balanceNumber);
        this.polkadotBalance = this.walletBalance;
        this.polkadotWallets[0].balance = this.walletBalance;
      } catch (err) {
        console.error(err);
      }
    },
    async getOctopusAssets() {
      const assets = await queryGetAllOctopusAssets(this.api);
      for (let i = 0; i < assets.length; i++) {
        const tokenId = assets[i][0].toHuman()[0];
        const id = assets[i][1].toHuman();
        const data = await queryGetAssetBalance(this.api, id, this.wallet.address);
        const assetData = {
          id,
          data,
          name: tokenId.split(".")[0],
          tokenId
        };
        this.octopusAsset.push(assetData);
      }
    },
    async fetchPolkadotBallance() {
      this.polkadotWallets.forEach(async wallet => {
        if (wallet.name !== "debio") {
          const data = this.octopusAsset.find(a => a.name === wallet.name);
          if (!data) return;
          const octopusData = this.octopusAsset.find(asset => asset.tokenId === wallet.tokenId);
          wallet.balance = this.web3.utils.fromWei(octopusData.data.balance.replaceAll(",", ""), wallet.unit);
          wallet.id = data.id;
          if (wallet.name === "usdt") this.setUSDTBalance(wallet.balance);
        }
      });
      this.setPolkadotWallet(this.polkadotWallets);
    },
    openWalletBinding() {
      this.$emit("showWalletBinding", true);
    },
    async checkMetamask() {
      const ethAddress = localStorage.getWalletAddress();
      this.isConnected = false;
      if (ethAddress) {
        this.isConnected = true;
      }
    },
    handleCopy(text) {
      navigator.clipboard.writeText(text);
      this.polkadotAddress = "Address Copied!";
      setTimeout(() => {
        this.polkadotAddress = text;
      }, 1000);
    }
  },
  watch: {
    lastEventData() {
      if (this.lastEventData) {
        this.fetchWalletBalance();
        this.fetchPolkadotBallance();
      }
    }
  },
  async mounted() {
    this.polkadotAddress = this.wallet.address;
    await this.fetchWalletBalance();
    await this.getOctopusAssets();
    await this.fetchPolkadotBallance();
    if (this.metamaskWalletAddress) await this.checkMetamaskBalance();
  }
};