import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"bottom":"","left":"","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center ml-10 mr-4"},'div',attrs,false),on),[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticStyle:{"cursor":"pointer"}},[_c(VIcon,[_vm._v("mdi-wallet")])],1)])])]}}])},[_c('div',[_c(VCard,{staticClass:"card-container"},[_c('div',[_c('strong',{staticClass:"notification-title"},[_vm._v("Wallet")]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"wallet-icon"},[_c(VImg,{attrs:{"src":require("@/assets/polkadot-icon.svg")}})],1),_c('strong',{staticClass:"notification-subtitle"},[_vm._v("Polkadot")])]),_c('div',{staticClass:"text-content"},[_vm._v("Address")]),_c('div',{staticClass:"d-flex justify-space-between address-wrapper"},[_c('div',{staticClass:"address-text"},[_vm._v(" "+_vm._s(_vm.polkadotAddress.substring(0, 25))+".. ")]),_c(VBtn,{staticClass:"ma-2",attrs:{"text":"","icon":"","color":"#5640A5","small":""},on:{"click":function($event){return _vm.handleCopy(_vm.polkadotAddress)}}},[_c('div',[_c(VImg,{attrs:{"src":require("@/assets/copy-icon.svg")}})],1)])],1),_c('div',{staticClass:"text-content"},[_vm._v("Balance")]),_vm._l((_vm.polkadotWallets),function(wallet){return _c('div',{key:wallet.id,staticClass:"d-flex"},[_c('div',{staticClass:"wallet-icon"},[_c(VImg,{attrs:{"alt":"no-list-data","src":require(("../assets/" + (wallet.icon) + ".svg")),"max-width":"24px","max-height":"24px"}})],1),_c('strong',{staticClass:"notification-subtitle"},[_vm._v(_vm._s(wallet.balance)+" "+_vm._s(wallet.currency === "USDTE" ? "USDT.e" : wallet.currency))])])})],2),_c('div',{staticClass:"divider"})])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }