import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c(VAppBar,{staticClass:"dg-app-bar",attrs:{"app":"","color":"secondary","dark":"","clipped-left":""}},[_c('div',{staticClass:"dg-app-bar-items-container"},[_c('a',{staticStyle:{"text-decoration":"none"},on:{"click":function($event){$event.stopPropagation();return _vm.goToDashboard($event)}}},[_c('div',{staticClass:"d-flex align-center"},[_c(VImg,{staticClass:"shrink mr-2",attrs:{"alt":"Vuetify Logo","contain":"","src":require("@/assets/debio-logo.png"),"transition":"scale-transition","width":"40"}}),_c('div',{staticClass:"text-h6 font-weight-bold light_primary--text"},[_vm._v(" DeBio "+_vm._s(_vm.isLab && "Lab")+" ")])],1)]),_c(VSpacer),_c('HeaderUserInfo'),_c('HeaderWallet'),_c('HeaderNotification',{attrs:{"role":'lab'}}),_c('div',{staticClass:"d-flex align-center ml-3 mr-5"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.logOut}},[_c(VIcon,[_vm._v("mdi-logout")])],1)])],1)]),_c('NavigationDrawer',{attrs:{"width":"200"}}),(!_vm.allowDashboard && _vm.isLabDashboard)?_c(VMain,{staticClass:"main"},[_c('router-view')],1):_c(VMain,{staticClass:"dg-dashboard-main ml-5"},[_c('DialogAlert',{attrs:{"show":_vm.dialogAlert,"btnText":_vm.alertTextBtn,"textAlert":_vm.alertTextAlert,"imgPath":_vm.alertImgPath,"imgWidth":_vm.imgWidth},on:{"toggle":function($event){_vm.dialogAlert = $event},"close":function($event){_vm.dialogAlert = false}}}),_c(VContainer,[_c('Breadcrumbs'),_c('div',{staticClass:"text-h5 secondary--text text--lighten-2"},[_c('b',[_vm._v(_vm._s(_vm.pageHeader))])])],1),_c('router-view')],1),_c('UnlockWalletDialog',{attrs:{"show":_vm.show},on:{"toggle":function($event){return _vm.toggle()}}}),_c('ErrorConnectionDialog',{attrs:{"show":_vm.showError},on:{"action":function($event){return _vm.onCloseError()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }