//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import Dialog from "./Dialog";
import Button from "./Button";
import store from "@/store/index";
import errorMessages from "../../src/constants/error-messages";
export default {
  name: "UnlockWalletDialog",
  components: {
    Dialog,
    Button
  },
  props: {
    show: Boolean
  },
  data: () => ({
    showPassword: false,
    password: "",
    isLoading: false,
    errorMessages: []
  }),
  computed: {
    ...mapGetters({
      api: "substrate/getAPI",
      pair: "substrate/wallet"
    })
  },
  methods: {
    async decryptWallet() {
      this.isLoading = true;
      if (this.errorMessages.length > 0) {
        this.errorMessages = [];
      }
      try {
        this.pair.unlock(this.password);
        await store.dispatch("substrate/getEncryptedAccountData", {
          password: this.password
        });
        this.isLoading = false;
        this.password = "";
        this.$emit("toggle");
      } catch (err) {
        this.isLoading = false;
        if (err.message === "Unable to decode using the supplied passphrase") this.errorMessages = [errorMessages.INCORRECT("password")];else this.errorMessages = [err.message];
      }
    }
  }
};