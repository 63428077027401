//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import v from "voca";
import { mapState, mapActions } from "vuex";
import Breadcrumbs from "@/views/Dashboard/Breadcrumbs";
// import MenuChangeRole from "@/components/MenuChangeRole";
import HeaderUserInfo from "@/components/HeaderUserInfo";
import NavigationDrawer from "@/components/NavigationDrawer";
import UnlockWalletDialog from "@/components/UnlockWalletDialog";
import ErrorConnectionDialog from "@/components/Dialog/DialogErrorConnection";
import HeaderNotification from "@/components/HeaderNotification";
import DialogAlert from "@/components/Dialog/DialogAlert";
import HeaderWallet from "@/components/HeaderWallet";
import localStorage from "@/lib/local-storage";
export default {
  name: "Dashboard",
  components: {
    Breadcrumbs,
    NavigationDrawer,
    // MenuChangeRole,
    HeaderUserInfo,
    UnlockWalletDialog,
    HeaderNotification,
    DialogAlert,
    ErrorConnectionDialog,
    HeaderWallet
  },
  data: () => ({
    showError: false,
    show: false,
    dialogAlert: false,
    alertTextBtn: "Continue",
    alertImgPath: "warning.png",
    alertTextAlert: "",
    imgWidth: "270",
    buttonMessage: "",
    titleText: ""
  }),
  async mounted() {
    if (!this.mnemonicData) {
      this.show = true;
    }
    await this.checkMetamask();
  },
  computed: {
    ...mapState({
      isServicesExist: state => state.substrate.isServicesExist,
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet,
      lastEventData: state => state.substrate.lastEventData,
      labAccount: state => state.substrate.labAccount,
      mnemonicData: state => state.substrate.mnemonicData,
      lastBlockData: state => state.substrate.lastBlockData
    }),
    isLab() {
      return this.$route.path.indexOf("lab") > 0;
    },
    isLabDashboard() {
      return this.$route.path === "/lab" || this.$route.path === "/lab/";
    },
    pageHeader() {
      return this.$route.meta.pageHeader ? this.$route.meta.pageHeader : v.titleCase(this.$route.name);
    },
    computeStakingStatus() {
      var _this$labAccount;
      return ((_this$labAccount = this.labAccount) === null || _this$labAccount === void 0 ? void 0 : _this$labAccount.stakeStatus) === "Unstaked";
    },
    allowDashboard() {
      var _this$labAccount2, _this$labAccount3;
      if (this.isServicesExist) {
        if (((_this$labAccount2 = this.labAccount) === null || _this$labAccount2 === void 0 ? void 0 : _this$labAccount2.stakeStatus) === "Unstaked" && ((_this$labAccount3 = this.labAccount) === null || _this$labAccount3 === void 0 ? void 0 : _this$labAccount3.verificationStatus) === "Unverified") return false;else return true;
      } else return false;
    }
  },
  watch: {
    $route(val) {
      var _val$query;
      if (val !== null && val !== void 0 && (_val$query = val.query) !== null && _val$query !== void 0 && _val$query.error) this.showError = true;
    },
    lastEventData(val) {
      if (val !== null) {
        var _this$lastBlockData;
        if (val.method === "LabStakeSuccessful") return;
        this.$store.dispatch("substrate/addListNotification", {
          address: this.wallet.address,
          event: val,
          block: (_this$lastBlockData = this.lastBlockData) === null || _this$lastBlockData === void 0 ? void 0 : _this$lastBlockData.block.header.number,
          role: "lab"
        });
      }
    }
  },
  methods: {
    ...mapActions({
      clearAuth: "auth/clearAuth"
    }),
    toggle() {
      this.show = false;
    },
    onCloseError() {
      this.showError = false;
      this.goToDashboard();
    },
    goToDashboard() {
      this.$router.push({
        path: "/lab"
      });
    },
    logOut() {
      this.clearAuth();
      localStorage.clear();
      this.$store.dispatch("lab/setProvideService", {});
      this.$store.commit("substrate/SET_IS_SERVICES_EXIST", false);
      this.$router.push("/login");
      const accounts = Object.keys(window.localStorage).filter(v => /account:/.test(v));
      accounts.forEach(a => {
        window.localStorage.removeItem(a);
      });
    }
  }
};