//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "DialogErrorConnection",
  components: {},
  props: {
    show: Boolean
  },
  methods: {
    onClick() {
      this.$emit("action");
    }
  }
};